:root {
  --font-size-impact-title: 60px;
  --font-size-impact-subtitle: 30px;
  --font-size-impact-bullet: 20px;
  --font-size-cost-reduction: 30px;
  --font-size-description: 20px;
  --font-size-hasubtxtcolored: 42px;
  --font-size-anytimeanywhere: 130px;
  --font-banner-subtext1: 60px;
  --font-banner-subtext2: 42px;
}
/* CustomButton.css */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
.custom-button {
  padding: 0px 14px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  min-height: 54px;
  font-size: 20px;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.1);
}

/* @media (max-width: 1024px) {
  .custom-button {
    font-size: 8px;
  }
}
@media (max-width: 770px) {
  .custom-button {
    font-size: 7px;
  }
}
@media (max-width: 480px) {
  .custom-button {
    padding: 2px 4px;
    height: 2vh;
    font-size: 4px;
  }
} */
/* HighlightWithActions.css */
.highlight-card {
  display: flex;
  background-color: #132a73;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  padding: 80px;
  gap: 40px;
}

.highlight-card img {
  display: block;
  height: 384px;
  object-fit: cover;
}

.highlight-card .main-text-smal {
  font-size: 32px;
  color: white;
  font-weight: bold;
}

.highlight-card .text-content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-right: 50px;
}

.highlight-card .main-text {
  color: white;
  font-size: 42px;
}

.highlight-card .highlight-text-big {
  color: #72dce2;
  font-size: inherit;
}
.highlight-card .main-text-big {
  font-size: 75px;
  color: white;
  font-weight: bold;
}

/* .highlight-card .highlight-text {
  font-size: 75px;
  color: #72dce2;
} */

.highlight-card .highlight-text {
  color: #72dce2;
  font-size: 42px;
  font-weight: bold;
}

.highlight-card .cta-text {
  color: white;
  font-size: 42px;
}

.highlight-card .cta-button {
  margin-top: 16px;
}

/* @media (max-width: 1024px) {
  .highlight-card {
    height: 32vh;
  }
  .highlight-card img {
    width: 50%;
    height: 67%;
  }
  .highlight-card .text-content {
    height: 23vh;
    margin-top: 3vh;
  }
  .highlight-card .main-text {
    font-size: 26px;
  }
  .highlight-card .highlight-text {
    font-size: 26px;
  }
} */
/* @media (max-width: 770px) {
  .highlight-card .main-text {
    font-size: 22px;
  }
  .highlight-card .highlight-text {
    font-size: 22px;
  }
  .highlight-card img {
    width: 38%;
    height: 67%;
    object-fit: contain;
  }
  .highlight-card {
    height: 30vh;
  }
  .highlight-card .text-content {
    height: 20vh;
    margin-top: 3.5vh;
  }
}
@media (max-width: 480px) {
  .highlight-card .main-text {
    font-size: 12px;
  }
  .highlight-card .highlight-text {
    font-size: 12px;
  }
  .highlight-card img {
    height: 53%;
    width: 50%;
  }
  .highlight-card {
    height: 22vh;
  }
  .highlight-card .text-content {
    height: 15vh;
    margin-top: 1.3vh;
  }
} */
/* HighlightWithActions2.css */
.highlight-card2 {
  display: flex;
  background-color: #132a73;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  padding: 78px;
  gap: 28px;
}

.highlight-card-image2 {
  display: block;
  height: 384px;
  object-fit: cover;
}

.highlight-card-content2 {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.highlight-card-title2 {
  color: white;
  font-size: 42px;
  max-width: 480px;
}

.highlight-card-highlight2 {
  color: #72dce2;
  font-size: 42px;
  font-weight: bold;
  line-height: 38px;
}

.highlight-card-text2 {
  color: white;
  font-size: 20px;
  margin: 0;
  line-height: 1.4;
  max-width: 580px;
}

/* @media (max-width: 1024px) {
  .highlight-card2 {
    height: 34vh;
  }
  .highlight-card-image2 {
    width: 47%;
    height: 68%;
  }
  .highlight-card-content2 {
    height: 25vh;
    margin-top: 2vh;
  }
  .highlight-card-title2 {
    font-size: 26px;
  }
  .highlight-card-highlight2 {
    font-size: 26px;
  }
  .highlight-card-text2 {
    font-size: 16px;
  }
}
@media (max-width: 770px) {
  .highlight-card2 {
    height: 30vh;
  }
  .highlight-card-image2 {
    width: 36%;
    height: 59%;
  }
  .highlight-card-content2 {
    height: 20vh;
    margin-top: 3vh;
  }
  .highlight-card-title2 {
    font-size: 22px;
  }
  .highlight-card-highlight2 {
    font-size: 22px;
  }
  .highlight-card-text2 {
    font-size: 14px;
  }
}
@media (max-width: 480px) {
  .highlight-card-title2 {
    font-size: 14px;
  }
  .highlight-card-highlight2 {
    font-size: 15px;
  }
  .highlight-card-text2 {
    font-size: 10px;
  }
  .highlight-card-content2 {
    height: 19vh;
    margin-top: 0vh !important;
  }
  .highlight-card2 {
    border-radius: 0;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    height: 22vh;
  }
  .highlight-card-image2 {
    margin: 3.5vh 5vw;
    height: 14vh;
  }
} */
:root {
  --font-banner-text: 80px;
  --font-banner-text2: 48px;
  --content-margin-top: -12vh;
  --content-margin-top2: -11vh;
}

body {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.inter-font {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.home-banner {
  margin: 24px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url("./Banner_Cover.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 529px;
  justify-content: flex-start;
}

.home-banner::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #132a73; /* Adjust the color and opacity as needed */
  opacity: 0.6;
  z-index: 1;
}

.home-banner .content {
  z-index: 2;
  font-size: 80px;
  color: white;
}

.banner-title {
  font-weight: bold;
}

.banner-subtext {
}
.banner-footer-text {
  font-size: 48px;
  color: #72dce2;
  font-weight: bold;
}

/* .overlay-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
} */

.content {
  padding-left: 72px;
  padding-top: 72px;
  display: flex;
  flex-direction: column;
}

.content-inside {
}

/* @media (max-width: 1024px) {
  :root {
    --font-banner-text: 54px;
    --font-banner-text2: 32px;
  }
  .home-banner {
    height: 39vh;
  }
  .content-inside {
    margin: -4vh 10vw;
  }
}
@media (max-width: 770px) {
  .home-banner {
    margin-top: -3vh;
  }
  .overlay-image {
    height: 52vh;
  }
  .content-inside {
    margin: -4vh 5vw;
  }
  :root {
    --font-banner-text: 54px;
    --font-banner-text2: 30px;
  }
}
@media (max-width: 480px) {
  .home-banner {
    margin: 0;
    height: 18vh;
    background-size: contain;
  }
  .overlay-image {
    width: 100vw;
    height: 40vh;
    margin-left: 0;
  }
  .content-inside {
    margin: -3vh 5vw;
  }
  :root {
    --font-banner-text: 24px;
    --font-banner-text2: 16px;
  }
} */

.hah-impact {
  padding: 12px 82px;
}

.impt-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.impt-content .lux-logo {
  object-fit: contain;
}

.impt-content .header h1 {
  color: #132a73;
  font-size: 60px;
  margin: 0;
}
.impt-content .header h3 {
  color: #72dce2;
  font-size: 42px;
  margin: 0;
  font-weight: 400;
  margin-bottom: 24px;
}

/* IconCard.css */
.icon-card {
  width: 180px;
}

.icon-card-image {
  width: 70px;
  object-fit: contain;
}

.icon-card-content h2,
.icon-card-content p {
  margin: 0;
  margin-top: 12px;
  font-family: "Inter", sans-serif;
}

.icon-card-title {
  font-size: 16px;
  font-weight: bold;
  color: white;
}

.icon-card-subtitle {
  font-size: 16px;
  color: white;
}

/* @media (max-width: 770px) {
  .icon-card-image {
    width: 38%;
    height: 40%;
  }
  .icon-card-title {
    font-size: 12px;
  }
  .icon-card-subtitle {
    font-size: 8px;
  }
}
@media (max-width: 480px) {
  .icon-card {
    height: 12vh;
  }
  .icon-card-content {
    margin-top: -3vh;
  }
  .icon-card-image {
    margin-bottom: 1vh;
    margin-left: 8vw;
  }
  .icon-card-title {
    font-size: 6px;
  }
  .icon-card-subtitle {
    font-size: 6px;
    margin-left: -2vw;
  }
} */
:root {
  --font-size-lux-text: 12px;
  --font-size-lux-subtext: 16px;
}

.lux-container {
  border: 1px solid #132a73;
  border-radius: 25px;
  width: 20vw;
  height: 8vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8vh;
}

.lux-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.lux-text {
  width: 11vw;
  color: #132a73;
}

.lux-image {
  width: auto;
  height: auto;
}

.lux-subtext {
  color: #132a73;
  margin-left: -8vw;
}

/* @media (max-width: 1024px) {
  :root {
    --font-size-lux-text: 8px;
    --font-size-lux-subtext: 12px;
  }
  .lux-container {
    height: 4vh;
    margin-top: 1.5vh;
  }
  .lux-image {
    width: 40px;
  }
}
@media (max-width: 770px) {
  .lux-content {
    margin-top: -1vh;
  }
  .lux-container {
    height: 4vh;
  }
  :root {
    --font-size-lux-text: 6px;
    --font-size-lux-subtext: 8px;
  }
  .lux-subtext {
    margin-top: -1vh;
  }
  .lux-image {
    border-radius: 40px;
  }
}
@media (max-width: 480px) {
  .lux-container {
    height: 3vh;
    width: 25vw;
    margin-top: 1vh;
  }
  .lux-content {
    margin-top: -0.5vh;
  }
  :root {
    --font-size-lux-text: 5px;
    --font-size-lux-subtext: 5px;
  }
  .lux-image {
    width: 35px;
    height: 12px;
  }
  .lux-subtext {
    margin-left: -8.5vw;
    margin-top: -0.1vh;
  }
} */

.prodInfo {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
}

/* ProductInfo.css */
.product-card {
  display: flex;
  width: 240px;
  height: 297px;
  flex: 0.25;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #fff;
  border-radius: 8px;
  position: relative;
}

.product-card img {
  padding: 24px;
  width: 150px;
  object-fit: contain;
}

.product-card p {
  color: #132a73;
  margin: 0;
  font-size: 20px;
  font-weight: 500;
}

.product-card-text {
  margin-top: -5vh;
}

/* @media (max-width: 1024px) {
  .product-card {
    width: 15vw;
    height: 15vh;
  }
}
@media (max-width: 768px) {
  .product-card {
    width: 120px;
    height: 16vh;
  }
  .product-card p {
    font-size: 12px;
  }
}
@media (max-width: 480px) {
  .product-card {
    width: 13vw;
    height: 9vh;
  }
  .product-card-img {
    font-size: 6px;
  }
  .product-card p {
    font-size: 8px;
  }
} */

.cardstats {
  max-width: 240px;
  min-width: 240px;
  display: flex;
  flex-direction: column;
  flex: 1 0 21%;
}

.title {
  color: #72dce2;
  font-size: 68px;
  font-weight: bold;
}

.content2 {
  color: #132a73;
  padding: 3px;
  font-size: 20px;
  margin-left: 0px !important;
}

/* @media (max-width: 1024px) {
  .cardstats {
    height: 12vh;
  }
  .content2 {
    font-size: 16px;
  }
}
@media (max-width: 770px) {
  .cardstats {
    height: 10vh;
  }
  .title {
    font-size: 32px !important;
  }
  .content2 {
    font-size: 15px;
  }
}
@media (max-width: 480px) {
  .cardstats {
    height: 13vh;
  }
  .title {
    font-size: 20px !important;
  }
  .content2 {
    font-size: 8px;
    margin-top: -5vh;
  }
} */
/* Your styles goes here. */
body {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.inter-font {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.parentofall {
  padding: 0 48px;
  background-color: white;
  margin-top: 116px;
}

.bannerSubtext {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 10vw;
  margin-left: 10vw;
}

.bannerSubtext2 {
  margin-left: 10vw;
  margin-right: 10vw;
  margin-top: -12vh;
}

.trudocstats {
  margin-top: 80px;
}

.trudocstats1 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 50px 70px;
}

.products-section {
  margin-top: 12px;
  padding: 0 82px;
}

.HAsubtext {
  font-size: 60px;
  color: #72dce2;
  margin: 48px 0;
}

.HAsubtext span {
  color: #132a73;
  font-size: inherit;
}

.hasubtextbtw {
  margin-right: 7px;
  font-size: 36px;
  --hasubtextbtw-font-size: 60px;
}

.impact-section {
  margin-top: 78px;
}

.impact {
  display: flex;
  justify-content: space-between;
  background-image: url("./ImpactBG.png");
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

.impct-title p {
  margin: 0;
  margin-bottom: 12px;
}

.impct-subtitle p {
  margin: 0;
  margin-top: 42px;
  font-weight: bold;
}

.impct-list {
  color: #132a73;
  padding-left: 18px;
  margin-top: 12px;
}

.impct-list li {
  margin-bottom: 12px;
}

.impactinner {
  margin: 48px;
  margin-left: 72px;
  flex: 0.6;
}

.desc {
  margin-top: 12px;
  color: #132a73;
  font-size: 20px;
  max-width: 480px;
}

.desc b {
  font-size: 24px;
}

.bulletdot {
  height: 5px;
  margin-right: 10px;
}

.impactImages {
  display: flex;
  gap: 0px 50px;
  flex-wrap: wrap;
  background-color: #132a73;
  max-width: 606px;
  border-radius: 80px;
  flex: 0.4;
  justify-content: center;
  align-items: center;
}

.impactImagesgrp1 {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 6vh;
}

.impactImagesgrp2 {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: -6vh;
}

.HighlightAction {
  margin-top: 48px;
}

.HighlightAction2 {
  margin-top: 5vh;
}

.HighlightActionSubtext {
  margin-left: 112px;
  margin-top: 82px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.HighlightActionSubtextcolored {
  flex: 0.4;
}

.HighlightActionSubtextcolored h1 {
  color: #132a73;
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 24px;
}

.HighlightActionSubtextcolored h1 span {
  color: #72dce2;
  font-size: inherit;
}

.hasubcomponent {
  display: flex;
  flex-direction: row;
}

.hasubcomponentbutton {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.podcastcardstext {
  display: flex;
}

.podcastcardstextinner1 {
  margin-left: 50vw;
  margin-top: -2vh;
  font-size: 20px;
  color: #132a73;
  font-weight: bold;
}

.podcastcardstextinner2 {
  margin-left: 27vw;
  margin-top: -2vh;
  font-size: 20px;
  color: #132a73;
  font-weight: bold;
}

.podcastcards {
  display: flex;
  flex-direction: row;
  flex: 0.6;
  gap: 20px;
  overflow-x: scroll;
  box-shadow: inset -10px 0 10px -10px rgba(0, 0, 0, 0.5);
}

.podcast-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.podcast-card::after {
  transition: background-color 0.3s ease, backdrop-filter 0.3s ease;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 33px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0);
  backdrop-filter: blur(0px);
  border-radius: inherit;
  z-index: 1;
}

.podcast-card:hover::after {
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(5px);
}

.podcast-card img {
  height: 323px;
  margin-bottom: 8px;
}

.anytime-anywhere-section {
  margin: 0 -48px;
}

.JHAH-section {
  display: flex;
  flex-direction: row;
  margin: 32px;
  justify-content: space-between;
}

.anytimeanywhere {
  margin-top: 80px;
  padding: 120px;
  background-color: #132a73;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.anytimeanywheretext p {
  margin: 0;
}

.anytimeanywhereSubtext {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 80px;
}

.JHAH {
  color: #72dce2;
}

.alongJHAH {
  color: #132a73;
  font-size: inherit;
  margin-left: 22px;
}

.alongJHAH2 {
  color: #132a73;
}

.footer-divider {
  border: 1px solid #72dce2;
}

.social {
  display: flex;
  justify-content: space-between;
  padding: 32px;
}

.social .icons {
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
  gap: 22px;
}

.socialImg {
  height: 93px;
  width: 93px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25%;
  background-color: #132a73;
}

.socialImg img {
  width: 93px;
  height: 93px;
  object-fit: contain;
}

.socialImg .moh-logo {
  margin-right: 20px;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background-color: #132a73;
  height: 137px;
  color: #e0e0e0;
  font-size: 30px;
}

/* @media (max-width: 1024px) {
  :root {
    --font-banner-subtext1: 32px !important;
    --font-banner-subtext2: 24px !important;
    --font-size-impact-title: 28px;
    --font-size-impact-subtitle: 20px;
    --font-size-impact-bullet: 12px;
    --font-size-cost-reduction: 20px;
    --font-size-description: 12px;
    --font-size-hasubtxtcolored: 26px;
  }
  .bannerSubtext2 {
    margin-top: -4vh;
  }
  .trudocstats2 {
    margin-top: 2vh;
  }
  .impact {
    height: 40vh;
  }
  .impactinner {
    height: 38vh;
    margin-top: 5vh;
  }
  .impactImages {
    height: 40vh;
    width: 45vh;
  }
  .HighlightActionSubtext {
    height: 27vh;
  }
  .indicatorContainer {
    gap: 2px;
  }
  .activeIndicator {
    width: 40px;
    height: 5px;
  }
  .inactiveIndicator {
    width: 40px;
    height: 5px;
  }
  .podcastcards {
    height: 28vh;
    margin-top: 3vh;
  }
  .postcardsprops {
    height: 17vh;
  }
  .anytimeanywhere {
    height: 35vh;
  }
  .anytimeanywhereSubtext {
    height: 30vh;
  }
  .JHAH {
    font-size: 56px;
  }
  .alongJHAH {
    font-size: 56px;
  }
  .social {
    height: 25vh;
  }
  .socialImg Img {
    width: 105px;
  }
  .footer {
    height: 20vh;
  }
}
@media (max-width: 770px) {
  :root {
    --font-size-impact-title: 18px;
    --font-size-impact-subtitle: 12px;
    --font-size-impact-bullet: 10px;
    --font-size-cost-reduction: 10px;
    --font-size-description: 10px;
    --font-size-hasubtxtcolored: 16px;
    --font-size-anytimeanywhere: 58px;
    --font-banner-subtext1: 22px !important;
    --font-banner-subtext2: 16px !important;
  }
  .bannerSubtext2 {
    margin-top: -2vh;
  }
  .bannerSubtext {
    margin-top: 0vh;
  }
  .trudocstats2 {
    margin-top: 2vh;
  }
  .hasubtextbtw {
    --hasubtextbtw-font-size: 24px;
  }
  .impact {
    height: 35vh;
  }
  .impactImages {
    height: 35vh;
    width: 40vw;
  }
  .impactImagesgrp {
    height: 15vh;
  }
  .indicatorContainer {
    gap: 12px;
  }
  .activeIndicator {
    width: 30px;
  }
  .inactiveIndicator {
    width: 30px;
  }
  .postcardsprops {
    height: 17vh;
  }
  .HighlightActionSubtext {
    height: 25vw;
  }
  .anytimeanywhere {
    height: 35vh;
  }
  .anytimeanywhereSubtext {
    height: 25vh;
  }
  .JHAH {
    font-size: 62px;
    margin-right: 20px;
  }
  .alongJHAH {
    font-size: 62px;
  }
  .social {
    height: 20vh;
  }
  .socialImg Img {
    height: 110px;
    width: 100px;
  }
  .footer {
    height: 20vh;
  }
}
@media (max-width: 480px) {
  :root {
    --font-size-impact-title: 14px;
    --font-size-impact-subtitle: 8px;
    --font-size-impact-bullet: 6px;
    --font-size-cost-reduction: 6px;
    --font-size-description: 6px;
    --font-size-hasubtxtcolored: 12px;
    --font-size-anytimeanywhere: 22px;
    --font-banner-subtext1: 16px !important;
    --font-banner-subtext2: 12px !important;
  }
  body {
    font-size: var(--font-size-medium);
  }
  .bannerSubtext2 {
    margin-top: -2vh;
  }
  .hasubtextbtw {
    --hasubtextbtw-font-size: 16px;
  }
  .impact {
    height: 30vh;
  }
  .impactImages {
    height: 30vh;
    width: 50vw;
  }
  .impactImagesgrp {
    height: auto;
  }
  .activeIndicator {
    width: 25px;
  }
  .inactiveIndicator {
    width: 25px;
  }
  .indicatorContainer {
    gap: 2px;
  }
  .podcastcards {
    height: 17vh;
  }
  .bannerSubtext {
    margin-top: 0vh;
  }
  .HighlightAction {
    margin-top: -2vh;
  }
  .HighlightActionSubtext {
    height: 15vh;
  }
  .postcardsprops {
    margin-top: 1vh;
    height: 12vh;
  }
  .anytimeanywhere {
    height: 22vh;
  }
  .anytimeanywheretext {
    margin-top: -3vh;
  }
  .anytimeanywhereSubtext {
    height: 22vh;
  }
  .impactinner {
    height: 30vh !important;
    margin-top: 5vh !important;
  }
  .JHAH {
    font-size: 24px;
    margin-right: 8px;
  }
  .alongJHAH {
    font-size: 24px;
  }
  .social {
    height: 15vh;
  }
  .socialImg Img {
    width: 55px;
  }
  .footer {
    height: 15vh;
    font-size: 14px;
  }
} */
.colored_card_text p {
  font-size: inherit;
  line-height: normal;
}

/*# sourceMappingURL=hah.css.map */

@media (max-width: 1280px) {
  .home-banner .content {
    font-size: 60px;
  }

  .impt-content .header h1 {
    font-size: 40px;
  }

  .impt-content .lux-logo {
    height: 45px;
  }

  .impt-content .header h3 {
    font-size: 24px;
  }

  .title {
    font-size: 54px;
  }
  .content2 {
    font-size: 16px;
  }

  /* Styles for screens smaller than 1024px */
  .conference .highlight-card img {
    height: 300px;
  }
  .conference .text-content {
    padding-right: 0;
  }
  .conference .main-text,
  .conference .highlight-text {
    font-size: 32px;
    line-height: 40px;
  }
  .conference .main-text-big,
  .conference .main-text-big .highlight-text-big {
    font-size: 45px;
  }
  .conference .main-text-smal {
    font-size: 17px;
  }

  .custom-button {
    font-size: 12px;
    min-height: 32px;
  }

  .HAsubtext {
    font-size: 42px;
  }

  .trudocstats1 {
    justify-content: flex-start;
  }

  .trudocstats {
    margin-top: 18px;
  }

  .prodInfo {
    flex-wrap: wrap;
    gap: 24px;
  }
  .impactinner {
    margin: 36px;
    flex: 0.4;
  }

  .impactImages {
    flex: 0.6;
  }

  .impct-title p {
    font-size: 40px;
  }

  .impct-subtitle p {
    font-size: 22px;
    margin-top: 12px;
  }
  .impct-list li {
    font-size: 14px;
    margin-bottom: 4px;
  }

  .desc b {
    font-size: 18px;
  }
  .desc {
    font-size: 16px;
  }
  .highlight-card-image2 {
    height: 280px;
  }
  .highlight-card2 {
    padding: 60px;
  }
  .highlight-card-content2 {
    padding: 0;
  }
  .highlight-card-title2 {
    font-size: 30px;
    line-height: 40px;
  }
  .highlight-card-highlight2 {
    font-size: 30px;
  }
  .highlight-card-text2 {
    font-size: 16px;
  }
  .HighlightActionSubtext {
    margin-left: 12px;
  }

  .HighlightActionSubtext h1 {
    font-size: 24px;
  }

  .podcast-card img {
    height: 260px;
  }

  .anytimeanywheretext p {
    font-size: 60px;
  }

  .anytimeanywhere {
    padding: 80px;
  }

  .anytimeanywhereSubtext {
    font-size: 42px;
  }

  .JHAH-section img {
    height: 140px;
  }

  .socialImg {
    height: 65px;
    width: 65px;
  }

  .socialImg svg {
    height: 35px;
    width: 35px;
  }
  .moh-logo img {
    height: 75px;
    width: 75px;
  }
}

@media (max-width: 1024px) {
  /* Styles for screens smaller than 1024px */
  .highlight-card,
  .impact {
    flex-direction: column;
  }
  .custom-button {
    margin-top: 18px;
  }
  .impactImages {
    max-width: 100%;
    padding: 46px;
    gap: 50px;
  }
  .icon-card {
    flex: 1 30%;
  }
}

@media (max-width: 768px) {
  /* Styles for screens smaller than 768px */
  .parentofall {
    padding: 0;
  }

  .home-banner {
    margin-left: 0;
    margin-right: 0;
  }
  .home-banner .content {
    font-size: 34px;
    padding-left: 32px;
  }

  .banner-footer-text {
    font-size: 32px;
  }

  .hah-impact {
    padding: 12px 26px;
  }

  .impt-content .header h1 {
    font-size: 24px;
  }

  .impt-content .lux-logo {
    height: 32px;
  }

  .impt-content .header h3 {
    font-size: 18px;
  }

  .title {
    font-size: 40px;
  }
  .content2 {
    font-size: 14px;
  }

  .trudocstats1 {
    justify-content: center;
  }
  .highlight-card {
    padding: 40px;
    flex-direction: column;
    border-radius: 0;
  }
  .conference .highlight-card img {
    height: 273px;
  }

  .conference .main-text,
  .conference .highlight-text {
    font-size: 22px;
    line-height: 30px;
  }

  .conference .main-text-big,
  .conference .main-text-big .highlight-text-big {
    font-size: 28px;
  }

  .conference .main-text-smal {
    font-size: 14px;
  }

  .products-section {
    padding: 0 40px;
  }

  .HAsubtext {
    font-size: 28px;
  }

  .prodInfo {
    justify-content: center;
    gap: 8px;
  }
  .podcastcards {
    margin-top: 22px;
  }
  .product-card {
    width: 200px;
    height: 210px;
  }
  .product-card img {
    height: 120px;
  }
  .product-card p {
    font-size: 16px;
  }
  .icon-card-image {
    width: 46px;
  }
  .icon-card-title {
    font-size: 14px;
  }
  .icon-card-subtitle {
    font-size: 12px;
  }
  .highlight-card2 {
    padding: 40px;
    flex-direction: column;
    border-radius: 0;
  }
  .highlight-card-title2 {
    font-size: 22px;
    line-height: 32px;
  }
  .highlight-card-highlight2 {
    font-size: 20px;
  }
  .highlight-card-text2 {
    font-size: 14px;
  }
  .HighlightActionSubtext {
    flex-direction: column;
    margin-left: 0;
    margin-top: 42px;
    padding: 0 20px;
  }
  .HighlightActionSubtext h1 {
    margin-bottom: 0;
  }
  .anytimeanywhere {
    padding: 32px;
  }

  .anytimeanywheretext p {
    font-size: 40px;
  }
  .anytimeanywhereSubtext {
    font-size: 28px;
  }

  .JHAH-section img {
    height: 100px;
    object-fit: contain;
  }
  .social {
    flex-direction: column;
    align-items: center;
    gap: 20px 0;
  }
  .JHAH-section {
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .alongJHAH {
    margin-left: 12px;
  }
  .anytimeanywheretext p {
    font-size: 28px;
  }

  .social .icons {
    flex-wrap: wrap;
    gap: 10px;
  }
}

@media (max-width: 480px) {
  /* Styles for screens smaller than 480px */
}
